import axios from 'axios';
import React, { useState } from 'react';

const ContactForm = () => {
    const resetForm = () => {
        return {
            message: '',
            name: '',
            email: '',
        };
    };
    const [formState, updateFormState] = useState(resetForm());
    const [resMessage, updateResMessage] = useState('');
    const { email, message, name } = formState;
    const inputHandler = (event) => {
        updateFormState({ [event.target.name]: event.target.value });
    };
    const submitHandler = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const contact = {
            email: formData.get('email'),
            name: formData.get('name'),
            message: formData.get('message'),
        };
        axios
            .post('https://slg-backend.herokuapp.com', contact)
            .then((response) => {
                updateFormState(resetForm());
                updateResMessage(response.data.message);
            })
            .catch((error) => {
                console.log(error);
                updateResMessage(error.message);
            });
    };
    return (
        <div className="form-box light-v">
            {resMessage && <p>{resMessage}</p>}
            <form
                id="contact-form"
                className="form"
                data-toggle="validator"
                onSubmit={submitHandler}
            >
                <div className="messages"></div>
                <div className="input__wrap controls">
                    <div className="form-group">
                        <label htmlFor="name">Etu- ja sukunimi *</label>
                        <input
                            id="name"
                            type="text"
                            name="name"
                            placeholder="Maija Meikäläinen"
                            value={name}
                            onInput={inputHandler}
                            required="required"
                            data-error="name is required."
                        />
                        <div className="help-block with-errors"></div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email *</label>
                        <input
                            id="email"
                            type="email"
                            name="email"
                            placeholder="example@example.com"
                            value={email}
                            onInput={inputHandler}
                            required="required"
                            data-error="Valid email is required."
                        />
                        <div className="help-block with-errors"></div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">
                            Viesti (Kuvaile mitä asiasi koskee...) *
                        </label>
                        <textarea
                            id="message"
                            className="form-control"
                            name="message"
                            placeholder="Hei, haluan antaa palautetta..."
                            value={message}
                            rows="4"
                            onInput={inputHandler}
                            required="required"
                            data-error="Please,leave us a message."
                        ></textarea>
                        <div className="help-block with-errors"></div>
                    </div>
                    <button className="custom-btn" type="submit">
                        <span className="custom-btn__label">Lähetä</span>
                        <span className="custom-btn__icon">
                            <span className="custom-btn__icon-small">
                                <svg
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 100 100"
                                >
                                    <polygon points="33.7,95.8 27.8,90.5 63.9,50 27.8,9.5 33.7,4.2 74.6,50 "></polygon>
                                </svg>
                            </span>
                            <span className="custom-btn__icon-circle">
                                <svg
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 100 100"
                                >
                                    <path
                                        className="bottomcircle"
                                        d="M18.2,18.2c17.6-17.6,46-17.6,63.6,0s17.6,46,0,63.6s-46,17.6-63.6,0"
                                    ></path>
                                    <path
                                        className="topcircle"
                                        d="M18.2,18.2c17.6-17.6,46-17.6,63.6,0s17.6,46,0,63.6s-46,17.6-63.6,0"
                                    ></path>
                                </svg>
                            </span>
                        </span>
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ContactForm;
