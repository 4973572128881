import React from 'react';
import Layout from '../components/Layout';
import ContactForm from '../components/ContactForm';
import uudetTilat from '../images/bg/uudet-tilat.jpeg';

const Contact = () => {
    return (
        <Layout>
            <section className="hero" data-overlay="5">
                <div className="container h-100">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Yhteystiedot & sijainti</h1>
                        </div>
                    </div>
                </div>
                <div className="herobg heroimage1"></div>
            </section>
            <section className="py-3 contact">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-info">
                                <div className="title-box">
                                    <h3>Ajanvaraus</h3>
                                    <div className="lineStagger"></div>
                                </div>
                                <div className="desc">
                                    <ul>
                                        <li>
                                            <strong>
                                                Parturi- Kampaamo palvelut
                                            </strong>
                                        </li>
                                        <li>
                                            Nopeasti ja helposti{' '}
                                            <strong>puhelimitse</strong>
                                        </li>
                                        <li>
                                            <a href="+358 (050) 38 01 400">
                                                <strong>050 38 01 400</strong>
                                            </a>
                                        </li>
                                        <li>
                                            <strong>Kynsien hoito</strong>
                                        </li>
                                        <li>
                                            Timma:{' '}
                                            <a
                                                href="https://timma.fi/yritys/smart-art-beauty-itis"
                                                target="__blank"
                                                referrerPolicy="no-referrer"
                                            >
                                                ajanvaraus
                                            </a>
                                        </li>
                                        <li>
                                            Voit tulla myös ilman ajanvarausta!
                                        </li>
                                    </ul>
                                </div>

                                <div className="info-inf">
                                    <div className="row pl-0 pr-0">
                                        <div className="col-md-12">
                                            <div className="in-box">
                                                <h4>Osoite</h4>
                                                <p>
                                                    Kauppakeskus Itis, Helsinki
                                                </p>
                                                <address>
                                                    <a href="https://maps.app.goo.gl/1Qezpw59r3eUBQja7">
                                                        Gotlanninkatu 11
                                                    </a>
                                                </address>
                                                <address>
                                                    00930 Helsinki
                                                </address>
                                                <div>
                                                    <iframe
                                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1982.2392326630877!2d25.08023357661876!3d60.20987224022948!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920f2a31d4a459%3A0xa97a9565da503ffd!2sGotlanninkatu%2011%2C%2000930%20Helsinki!5e0!3m2!1sfi!2sfi!4v1712385210606!5m2!1sfi!2sfi"
                                                        allowfullscreen=""
                                                        loading="lazy"
                                                        referrerpolicy="no-referrer-when-downgrade"
                                                    ></iframe>
                                                </div>
                                                <p>
                                                    <strong>
                                                        Aukioloajat:
                                                    </strong>
                                                </p>
                                                <p>ma-pe: 9-19</p>
                                                <p>la: 9-16</p>
                                                <p>su: suljettu</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="in-box">
                                                <h4>Puhelinnumero</h4>
                                                <p>050 38 01 400</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="in-box">
                                                <h4>Email</h4>
                                                <a href="salonlookinggoodfi@gmail.com">
                                                    salonlookinggoodfi@gmail.com
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <h4>Uudet tilat</h4>
                                    <picture>
                                        <img
                                            src={uudetTilat}
                                            alt="Uudet tilat"
                                            className="img-fluid"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div className="title-box mt-4">
                                <h3>Anna palautetta</h3>
                            </div>
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Contact;
